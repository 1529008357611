<template>
    <v-card flat>
        <v-card-text v-if="statsCanBeComputed">
            <div class="my-5" v-for="section, sectionIndex in form.sections" v-if="totalStats[sectionIndex].amount > 0">
                <v-divider></v-divider>
                <h1 class="text-center white--text py-3 primary font-weight-bold" v-text="section.name"></h1>
                <v-divider></v-divider>
                <span class="d-flex flex-row align-center justify-start">
                    <div style="width : 100%">
                        <span v-for="question, questionIndex in section.questions" v-if="question.type == 'slider'" class="my-5 d-flex flex-column align-center justify-center">
                            <h3 v-text="question.title"></h3>
                            <span v-if="question.type == 'slider'" class="d-flex align-center justify-start">
                                <VueApexCharts :options="donutsChartOptions" :series="stats[sectionIndex][questionIndex].series"></VueApexCharts>
                                <v-icon class="ml-5 mr-6" x-large>mdi-arrow-right</v-icon>
                                <div style="width: 250px;">
                                    <v-img height="100px" width="100px" contain :src="require(`@/assets/images/smiley/smiley ${smileyOrder[Math.round(stats[sectionIndex][questionIndex].mean)]}.svg`)"></v-img>
                                </div>
                            </span>
                        </span>
                        <v-divider></v-divider>
                        <span class="my-5 d-flex flex-column align-center justify-center">
                            <h2 v-text="section.name + ' (Total)'" class="accent--text"></h2>
                            <span class="d-flex align-center justify-start">
                                <VueApexCharts :options="donutsChartOptions" :series="totalStats[sectionIndex].series"></VueApexCharts>
                                <v-icon class="ml-5 mr-6" x-large>mdi-arrow-right</v-icon>
                                <div style="width: 250px;">
                                    <v-img height="100px" width="100px" contain :src="require(`@/assets/images/smiley/smiley ${smileyOrder[Math.round(totalStats[sectionIndex].mean)]}.svg`)"></v-img>
                                </div>
                            </span>
                        </span>
                    </div>
                </span>
            </div>
            <div class="my-5">
                <v-divider></v-divider>
                <h1 class="text-center white--text py-3 primary font-weight-bold">TOTAL</h1>
                <v-divider></v-divider>
                <span class="d-flex flex-row align-center justify-start">
                    <div style="width : 100%">
                        <span class="my-5 d-flex flex-column align-center justify-center">
                            <span class="d-flex align-center justify-start">
                                <VueApexCharts :options="donutsChartOptions" :series="totalStats.total.series"></VueApexCharts>
                                <v-icon class="ml-5 mr-6" x-large>mdi-arrow-right</v-icon>
                                <div style="width: 250px;">
                                    <v-img height="100px" width="100px" contain :src="require(`@/assets/images/smiley/smiley ${smileyOrder[Math.round(totalStats.total.mean)]}.svg`)"></v-img>
                                </div>
                            </span>
                        </span>
                    </div>
                </span>
            </div>
        </v-card-text>
        <v-card-text v-else>
            données introuvables
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: "SatisfactionTab",
    props : ['responses', 'form'],
    components: {
        VueApexCharts
    },
    data() {
        return {
            smileyOrder: ['R', 'O', 'J', 'VJ', 'VV'],
            donutsChartOptions: {
                chart: {
                    type: 'donut',
                },
                colors: ['#CB4F24','#E7B142', '#F7EA3A','#A2C037', '#6AA842'],
                labels: ['4', '3', '2', '1', '0'],
                stroke: {
                    width: 0,
                },
                states: {
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    position: 'center',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '50%',
                        },
                        expandOnClick: false
                    },
                },
                responsive: [{
                    breakpoint: Infinity,
                    options: {
                        chart: {
                            height: '250',
                            width: '250',
                        },
                    }
                }]
            },
        }
    },
    computed: {
        statsCanBeComputed(){
            for(let response of this.responses){
                for(let section of response.sections){
                    for(let question of section.questions){
                        if(question.type == 'slider'){
                            return true
                        }
                    }
                }
            }
            return false
        },
        stats(){
            let stats = []
            for(let response of this.responses){
                for(let [sectionIndex, section] of response.sections.entries()){
                    if(!stats[sectionIndex]){
                        stats[sectionIndex] = []
                    }
                    for(let [questionIndex, question] of section.questions.entries()){
                        if(!stats[sectionIndex][questionIndex]){
                            stats[sectionIndex][questionIndex] = {
                                series : [0,0,0,0,0],
                                mean : 0,
                            }
                        }
                        if(question.type == 'slider'){
                            stats[sectionIndex][questionIndex].series[question.answer] += 1
                            let total = 0
                            let amount = 0
                            for(let [index, value] of stats[sectionIndex][questionIndex].series.entries()){
                                total += value * index
                                amount += value
                            }
                            if(amount > 0){
                                stats[sectionIndex][questionIndex].mean = total / amount
                            }
                        }
                    }
                }
            }
            return stats
        },
        totalStats(){
            let totalStats = {
                sections : [],
                total : {
                    series : [0,0,0,0,0],
                    mean : 0,
                }
            }
            let mainTotal = 0
            let mainAmount = 0
            for(let response of this.responses){
                for(let [sectionIndex, section] of response.sections.entries()){
                    if(!totalStats[sectionIndex]){
                        totalStats[sectionIndex] = {
                            series : [0,0,0,0,0],
                            mean : 0,
                            total : 0,
                            amount : 0,
                        }
                    }
                    for(let question of section.questions){
                        if(question.type == 'slider'){
                            totalStats[sectionIndex].series[question.answer] += 1
                            totalStats.total.series[question.answer] += 1
                            mainAmount = 0
                            mainTotal = 0
                            totalStats[sectionIndex].amount = 0
                            totalStats[sectionIndex].total = 0
                            for(let [index, value] of totalStats[sectionIndex].series.entries()){
                                totalStats[sectionIndex].total += value * index
                                mainTotal += value * index
                                totalStats[sectionIndex].amount += value
                                mainAmount += value
                            }
                        }
                    }
                    if(totalStats[sectionIndex].amount > 0){
                        totalStats[sectionIndex].mean = totalStats[sectionIndex].total / totalStats[sectionIndex].amount
                    }
                }
                if(mainAmount > 0){
                    totalStats.total.mean = mainTotal / mainAmount
                }
            }
            return totalStats
        }
    },
}
</script>